export const PROJECT_ID = 'onlyonebank-preprod';
export const CLIENT_ID = '743930387020-um07s54t2dcnuhecu7hs1lud6083uhef.apps.googleusercontent.com';

export const BASE_URL = 'https://api-preprod.onlyonebank.io';
export const REDIRECT_URL = 'https://backend-preprod.onlyonebank.io';
export const API_KEY = 'AIzaSyAjRx6U3ibvt5mhGlz761rjMOWwdZJUmCI';
export const BACKEND_API_BASE_URL = 'https://jarvis-preprod.onlyonebank.io';
export const FRIDAY_API_BASE_URL = 'https://friday-preprod.onlyonebank.io';
export const CEREBRO_API_BASE_URL = 'https://cerebro-preprod.onlyonebank.io';
export const SMAUG_API_BASE_URL = 'https://smaug-preprod.onlyonebank.io';
export const SIDEKICK_API_BASE_URL = 'https://sidekick-preprod.onlyonebank.io';
export const NOTIF_CENTER_API_BASE_URL = 'https://notif-center-preprod.onlyonebank.io';
export const SAURON_API_BASE_URL = 'https://sauron-preprod.onlyonebank.io';
export const SCROOGE_API_BASE_URL = 'https://scrooge-preprod.onlyonebank.io';

export const ONLYONE_WEB_APP_BASE_URL = 'https://app-preprod.onlyonebank.io';
